.projectpage-container {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.project-container {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 700px;
}
.project--subtitle {
  color:grey;
  font-size: 18px;
  font-weight: 500;
  margin-top: -10px;
}
.project--heading-large {
  font-size: 26px;
  font-weight: 500;
}
.project--heading {
  font-size: 22px;
  font-weight: 500;
}
.italics {
  font-style: italic;
}
.project--title {
  font-family: 'Roboto Slab',sans-serif;
  color: #0033cc;
  padding:none; 
  font-size: 36px;
}
.project--paragraph {
  padding: 0px 8px; /* change to   24px padding with mobile */
  margin-top: 4px;
  font-size: 18px;
  line-height: 1.8;
  width: 100%;
}
.project-link {
  color:#303030;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  overflow:hidden;
  text-decoration: none;
  margin: -1px;
}
.project-link:after {
  content: "";
  background-color: #85a4ff;
  width:0%;
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: -1;
  transform:scale(1.05);
  transition: 0.35s cubic-bezier(0.25, 0.1, 0, 2.05);
}
.project-link:hover {
  color:#303030;
  transition: 0.35s cubic-bezier(0.25, 0.1, 0, 2.05);
}
.project-link:hover:after {
  left: 0px;
  bottom: -2px;
  width: 100%;
  height: 40%;
}
.project-link-container {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  margin-bottom: 10px;
  justify-content: space-evenly;
  width: 350px;
  
}
.project--image {
  width: 100%;
}
.bold {
  font-weight: 600;
  padding: 0px 0px;
  display: inline;
}