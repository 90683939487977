.error-container {
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-link {
  margin-top: 10px;
  width: max-content;
  height: max-content;
  text-decoration: none;
  font-size: 18px;
  overflow:hidden;
  position: relative;
  overflow:hidden;
  font-weight: 600;
  color: #303030;
}
.error-link:after {
  content: "";
  background-color: #85a4ff;
  width:0%;
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: -1;
  transform:scale(1.05);
  transition: 0.35s cubic-bezier(0.25, 0.1, 0, 2.05);
}
.error-link:hover {
  color:#303030;
  transition: 0.35s cubic-bezier(0.25, 0.1, 0, 2.05);
}
.error-link:hover:after {
  left: 0px;
  bottom:0px;
  width: 100%;
  height: 40%;
}