
.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  
}
.home-intro {
  margin-top: 12vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 120px;
  max-width: 672px;
}
.home-intro-name {
  color:#85a4ff;
  text-transform: uppercase;
  font-size: 32px;
  font-weight: 600;
  margin-top: -4px;
  margin-bottom: -52px;
}
.home-intro-title {
  font-family: 'Roboto Slab',sans-serif;
  font-size: 72px;
  margin-bottom: -10px;
}
.home-intro-descrip {
  margin-bottom: -10px;
  font-size: 24px;
}
.home-featured-posts-container {
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
  width: 672px;
}
.home-featured-posts-title {
  font-family: 'Roboto Slab',sans-serif;
  font-size: 28px;
  font-weight: bolder;
}
.home-featured-posts {
  list-style: none;
  padding: 0px 0px;
  display: flex;
  flex-wrap:wrap;
  justify-content: space-between;
  align-items: center;
}
.featured-post {
  margin-top: 6px;
  max-width: 320px;
  height: 420px;
  position: relative;
  text-align: center;
}

.post-image {
  width: 100%;
  height: 100%;
  border-radius: 22px;
}
.post-descrip {
  color: #303030;
  position: absolute;
  top: 8px;
  left: 16px;
  font-size: 18px;
  font-weight: bold;
}
.post-descrip-detailed {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: bold;
  color:transparent;
  background-color: transparent;
}
.post-descrip-detailed.active {
  background-color: transparent;
  transition: color 0.4s ease;
  color:white;
  font-size: 18px;
  font-weight:normal;
}
.featured-posts-loading {
  font-size: 24px;
  margin: 4px;
}

/* mobile */ 

@media screen and (max-width: 960px) {
  .home-intro {
    margin-top: 10px;
    max-width: 370px;
    padding: 4px;
    margin-bottom:100px;
  }
  .home-intro-title {
    font-family: 'Roboto Slab',sans-serif;
    font-size: 52px;
  }
  .home-intro-name {
    font-size: 24px;
    margin-top: -4px;
    margin-bottom: -24px;
  }
  .home-intro-descrip {
    font-size: 24px;
  }
  .featured-post {
    margin-left: 6vw;
  }
  .home-featured-posts-container {
    padding: 4px;
    margin-top: -4px;
    width: 100%;
    max-width: 370px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
}
