.projects-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.projects-container {
  width: 690px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.featured-post {
  margin-top: 6px;
  width: 320px;
  height: 260px;
}
.projectpage-header {
  font-size: 72px;
  font-weight: bold;
  color: #85a4ff;
  margin-top: 50px;
  margin-right: 400px;
  font-family: 'Roboto Slab', sans-serif;
}

.post-container {
  text-decoration: none;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 2px;
  margin-bottom: 12px;
}
.post-container:hover {
  transform: scale(1.05);
  transition: 0.3s ease-in;
}
.post-image {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
.post-description {
  margin-left:4px;
  font-size: 20px;
}
.post-detailed {
  margin-left:4px;
  font-size: 18px;
  color: grey;
}

@media screen and (max-width: 960px) {
  .projects-container {
    max-width: 370px;
  }
  .projectpage-header {
    margin-left: 320px;
  }
  .post-description {
    margin-top: 4px;
    margin-left:26px;
  }
  .post-detailed {
    margin-left: 26px;
  }
  .post-container{
    width: 370px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 12px;
  }
}