
.bold {
  font-weight: 600;
  padding: 0px 0px;
  display: inline;
}
.blogpost--heading-large {
  font-size: 26px;
  font-weight: 500;
}
.blogpost--heading{
  font-size: 22px;
  font-weight: 500;
}
.italics {
  font-style: italic;
}
.blogpage-container{
  padding-top: 10px;
  position: relative;
  height: auto;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.blog--container{
  background-color: white;
  margin-top: 32px;
  display:flex;
  z-index: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  border-radius: 0.25rem;
  width:700px;
}
.blog--body {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}
.blog--subtitle {
  color:grey;
  font-size: 18px;
  font-weight: 500;
  margin-top: -10px;
}
.blog--title { 
  font-family: 'Roboto Slab',sans-serif;
  color: #0033cc;
  padding:none; 
  font-size: 36px;
  width:700px;
  text-align: center;
}

.blog--paragraph {
  padding: 0px 8px; /* change to   24px padding with mobile */
  margin-top: 4px;
  font-size: 18px;
  line-height: 1.8;
  width: 100%;
  
  
}
.blog--code-container {
  width: 100%;
  margin: -24px 0px;
}

.blog--image {
  width: inherit;
  margin-bottom: 4px;
}

/* links */

.blog-link {
  color:#afc3ff;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  overflow:hidden;
  text-decoration: none;
  margin: -1px;
}
.blog-link:after {
  content: "";
  background-color: #afc3ff;
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: -1;
  transform:scale(1.05);
  transition: 0.35s cubic-bezier(0.25, 0.1, 0, 2.05);
}
.blog-link:hover {
  color:black;
  transition: 0.35s cubic-bezier(0.25, 0.1, 0, 2.05);
}
.blog-link:hover:after {
  left: 0px;
  bottom: -2px;
  width: 100%;
  height: 20%;
}

.blog--blockquote-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.blog--blockquote{
  text-align: center;
  font-size: 20px;
  margin: 18px;
  width: 100%;
  color: #555555;
  padding: 20px 0px;
  max-width: 60%;
  border-left:10px solid #0033cc ;
  line-height:1.6;
  position: relative;
  background:#EDEDED;
}

.blog--blockquote::before{
  font-family:Arial;
  content: "\201C";
  color:#0033cc;
  font-size:4em;
  position: absolute;
  left: 10px;
  top:-10px;
}

.blog--blockquote::after{
  content: '';
}

.blog--list {
  font-size: 18px;
  margin-left: 8px;
  padding: 0px 0px;
  margin-top: 0px;
  list-style: disc;
}
.blog--list-item {
  margin-left: 24px;
  margin-top: -12px;
  margin-bottom: -12px;
}
@media screen and (max-width: 960px) {
  .blogpage-container{    
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .blog--container{
    padding: none;
    margin:none;
    width: 370px;
  }
  .blog--paragraph{
    padding:0px 8px;
    max-width: 370px;
    
  }
  .blog--title {
    margin-top: -10px;
    width: 370px;
    
  }
  .blogpost--heading{
    
    padding:0px 8px;
    background-color: none;
  }
  .blogpost--heading-large {
    padding:0px 8px;
  }
}