.notfound-link{
  text-decoration: none;
  font-size:22px;
  height: 100%;
  position: relative;
  overflow:hidden;
  font-weight: 600;
  color: #303030;
}
.notfound-link:after {
  content: "";
  background-color: #85a4ff;
  width:0%;
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: -1;
  transform:scale(1.05);
  transition: 0.35s cubic-bezier(0.25, 0.1, 0, 2.05);
}
.notfound-link:hover {
  color:#303030;
  transition: 0.35s cubic-bezier(0.25, 0.1, 0, 2.05);
}
.notfound-link:hover:after {
  left: 0px;
  bottom:0px;
  width: 100%;
  height: 40%;
}

.notfound-header {
  margin-top: 200px;
  font-size: 32px;
}
.notfound-container {
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 960px) {
  .notfound-container {
    max-width: 370px;
    padding: 4px;
    margin-top: 20px;
    text-align: center;
  }
}