.projects-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.projects-container {
  width: 690px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.projects-header {
  font-size: 72px;
  font-weight: bold;
  color: #85a4ff;
  margin-top: 50px;
  margin-right: 36vw;
  margin-bottom: -12px;
  font-family: 'Roboto Slab', sans-serif;
}
.skills-intro {
  font-weight:normal;
  width: 715px;
  font-size: 22px;
  margin-bottom:0px;
}
.skills-list {
  width: inherit;
  padding: 0px 0px;
  list-style: none;
}
.skill-item {
  width: 100%;
  font-size: 20px;
  padding: 0px 0px;
  margin-left: 16px;
}
.skills-container {
  width: 226px;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 2px;
  
}
.skills-header {
  font-family: 'Roboto Slab',sans-serif;
  font-size:24px;
  margin-bottom: -8px;
}
.subcert-header {
  font-family: 'Roboto Slab',sans-serif;
  font-size:24px;
  margin-bottom: -8px;
}
.certification-header {
  font-size: 56px;
  font-weight: bold;
  color: #85a4ff;
  margin-top: 24px;
  margin-right: 25vw;
  margin-bottom: -12px;
  font-family: 'Roboto Slab', sans-serif;
}

.certification-list {
  width: inherit;
  padding: 0px 0px;
  list-style: none;
}
.certification-item {
  width: 100%;
  font-size: 20px;
  padding: 0px 0px;
  margin-left: 16px;
}
.certification-container {
  width: 226px;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: -460px;
}

@media screen and (max-width: 960px) {
  .projects-page {
    max-width: 370px;
  }
  .projects-container {
    width:370px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .skills-intro{
    width: 100%;
    padding: 0px 6px;
  }
  .skills-container {
    height: fit-content;
    margin-bottom: 12px;
  }
  .certification-header {
    font-size: 56px;
    font-weight: bold;
    color: #85a4ff;
    margin: 0px 0px;
    font-family: 'Roboto Slab', sans-serif;
  }
  .certification-container {
    width: 370px;
    margin: 0px 0px;
    padding: 0px 6px;
    align-items:flex-start;
  }
  .subcert-header {
    margin-left: 44px;
  }
  .certification-item {
    margin: 0px 0px;
  }
  .certification-list {
    padding: 4px 12px;
  }
  /*
<h2 className="subcert-header">AWS</h2>
          <ul className="certification-list">
            <li className="certification-item"> <BsCaretRightFill size={17} style={{"margin-right":"2px"}}/> Associate Solutions Architect - 3/15/2021</li>
          </ul>

  */
}